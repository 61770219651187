module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.neurond.com","noTrailingSlash":true,"noQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Neurond AI","short_name":"Neurond AI","start_url":"/","background_color":"#fff","theme_color":"#fff","display_override":["window-control-overlay","minimal-ui"],"display":"standalone","icon":"src/assets/icon/neurondAI_favicon.svg","icons":[{"src":"src/assets/icon/favicon36.png","sizes":"36x36","type":"image/png","purpose":"any maskable"},{"src":"src/assets/icon/favicon48.png","sizes":"48x48","type":"image/png","purpose":"any maskable"},{"src":"src/assets/icon/favicon72.png","sizes":"72x72","type":"image/png","purpose":"any maskable"},{"src":"src/assets/icon/favicon96.png","sizes":"96x96","type":"image/png","purpose":"any maskable"},{"src":"src/assets/icon/favicon144.png","sizes":"144x144","type":"image/png","purpose":"any maskable"},{"src":"src/assets/icon/favicon150.png","sizes":"150x150","type":"image/png","purpose":"any maskable"},{"src":"src/assets/icon/favicon180.png","sizes":"180x180","type":"image/png","purpose":"any maskable"},{"src":"src/assets/icon/favicon192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"src/assets/icon/favicon310.png","sizes":"310x310","type":"image/png","purpose":"any maskable"},{"src":"src/assets/icon/favicon512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e12740d8a74aaae3b8b4e24eeec0bf04"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en"],"defaultLanguage":"en","path":"/github/workspace/src/locales","i18nextOptions":{"lowerCaseLng":true,"saveMissing":false,"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang?/blog/:uid","getLanguageFromPath":true,"excludeLanguages":["jp"]},{"matchPath":"/ignored-page","languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://neurond-wp.azurewebsites.net/graphql","schema":{"perPage":10,"requestConcurrency":5,"previewRequestConcurrency":2,"timeout":600000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"verbose":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
